import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase, ref, onValue, set, get, remove } from "firebase/database";
const CryptoJS = require("crypto-js");

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyA7DP8MOQiEyw27NhCCbVNMli1zsVPHc2I",
  authDomain: "classroomgpt-mvp.firebaseapp.com",
  projectId: "classroomgpt-mvp",
  storageBucket: "classroomgpt-mvp.appspot.com",
  messagingSenderId: "708335509441",
  appId: "1:708335509441:web:5204c9c6d4a30bf90b9940",
  measurementId: "G-ZQR3YH1ZW8",
  databaseUrl: "https://classroomgpt-mvp-default-rtdb.firebaseio.com",
};

// var admin = require("firebase-admin");
// var serviceAccount = require("./classroomgpt-mvp-firebase-adminsdk-puakg-357d0d786a.json");

// admin.initializeApp({
//   credential: admin.credential.cert(serviceAccount),
//   databaseURL: "https://classroomgpt-mvp-default-rtdb.firebaseio.com"
// });

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();

export function getTeacherLessons(userId, callback) {
  const lessonsRef = ref(database, "lessons/" + userId);
  onValue(lessonsRef, (snapshot) => {
    const data = snapshot.val();
    if (data === null || data === "null") {
      return;
    } else {
      const jsonData = JSON.stringify(data);
      callback(jsonData);
    }
  });
}

export function setTeacherLessons(userId, callback) {
  const lessonsRef = ref(database, "lessons/" + userId);
  set(lessonsRef, {
    lesson_name: "This is the lesson name.",
    lesson_description: "This is the lesson description.",
  });
}

export function submitFeedback(feedback) {
  const epochTime = Date.now();
  const timeString = epochTime.toString();
  const hashValue = CryptoJS.SHA256(timeString).toString();

  const feedbackRef = ref(database, "feedback/" + hashValue);
  set(feedbackRef, {
    feedback_time: timeString,
    feedback_description: feedback,
  });
}

// DEPRECATED
export function initializeNewLesson(
  userId,
  lessonName,
  learningObjective,
  listOfABCDs
) {
  // Unique id for lesson
  const epochTime = Date.now();
  const timeString = epochTime.toString();
  const hashValue = CryptoJS.SHA256(timeString).toString();

  // Formatted DD/MM/YYYY
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US");

  let safeLesson = lessonName.replace(/\./g, "_");
  const lessonsRef = ref(database, "lessons/" + userId + "/" + hashValue);

  const listOfABCDsJSON = JSON.stringify(listOfABCDs);
  console.log(listOfABCDsJSON);

  set(lessonsRef, {
    lesson_name: safeLesson,
    lesson_description: learningObjective,
    lesson_date: formattedDate,
    history: listOfABCDsJSON,
  });
}

// NEW
export function initializeNewLessonPlan(userId) {
  // Unique id for lesson
  const epochTime = Date.now();
  console.log(epochTime);
  const timeString = epochTime.toString();
  const lessonId = CryptoJS.SHA256(timeString).toString();

  // MM/DD/YYYY
  const dateObject = new Date(epochTime);
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
  const day = dateObject.getDate().toString().padStart(2, "0");
  const year = dateObject.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;

  const lessonsRef = ref(database, "lessons/" + userId + "/" + lessonId);
  set(lessonsRef, {
    lessonComplete: false,
    isShared: false,
    title: "Default title",
    description: "This is the lesson's default description. Edit me!",
    date: formattedDate,
    preLesson: {
      classDescription: "",
      academicStandard: "",
      learnerNeeds: "",
      identifiedStandard: "",
    },
    learningObjective: {
      audience: "",
      behavior: "",
      condition: "",
      degree: "",
      finalObjective: "",
    },
    assessment: {
      preAssessment: false,
      formativeAssessment: false,
      summativeAssessment: false,
      preAssessmentFeedback: "",
      formativeAssessmentFeedback: "",
      summativeAssessmentFeedback: "",
    },
    lessonFlow: {
      materialConstraints: "",
      anticipatorySet: "",
      objectiveAndPurpose: "",
      input: "",
      modeling: "",
    },
    preLessonSet: false,
    learningObjectiveSet: false,
    assessmentSet: false,
    lessonFlowSet: false,
    lid: lessonId,
  });
  return lessonId;
}

export function deleteLesson(userId, lessonId) {
  const lessonToDeleteRef = ref(database, "lessons/" + userId + "/" + lessonId);
  remove(lessonToDeleteRef);
}

export function checkIfLessonExists(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);
  onValue(lessonRef, (snapshot) => {
    const data = snapshot.val();
    const jsonData = JSON.stringify(data);
    callback(jsonData);
  });
}

export function getClassroomInformationInPreLesson(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);
  onValue(lessonRef, (snapshot) => {
    const data = snapshot.val();
    const jsonData = JSON.stringify(data);
    callback(jsonData);
  });
}

export async function updateLessonTitle(userId, lessonId, newTitle) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.title = newTitle;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error updating lesson title.", error);
  }
}

export async function updateLessonDescription(
  userId,
  lessonId,
  newDescription
) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.description = newDescription;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error updating lesson title.", error);
  }
}

export async function updateLessonTitleAndDescriptionSameTime(
  userId,
  lessonId,
  newTitle,
  newDescription
) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.title = newTitle;
    data.description = newDescription;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error updating lesson title.", error);
  }
}

// PRE-LESSON FUNCTIONS

export async function preLessonSlide1(
  userId,
  lessonId,
  classDescription,
  academicStandard
) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.preLesson.classDescription = classDescription;
    data.preLesson.academicStandard = academicStandard;
    await set(lessonRef, data);
  } catch (error) {
    console.error(
      "Error adding academic standard and class description.",
      error
    );
  }
}

export async function preLessonSlide2(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    callback(data.preLesson.classDescription, data.preLesson.academicStandard);
  } catch (error) {
    console.error(
      "Error retrieving academic standard and class description for step 2 of pre-lesson.",
      error
    );
  }
}

export async function preLessonSlide4(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    console.log("data i am getting from firebase");
    console.log(data);

    let learnerNeeds = data.preLesson.learnerNeeds;
    let identifiedStandard = data.preLesson.identifiedStandard;

    callback(learnerNeeds, identifiedStandard);
  } catch (error) {
    console.error(
      "Error retrieving learner needs and identified standard for step 4 of pre-lesson.",
      error
    );
  }
}

export async function preLessonSetLearnerNeeds(userId, lessonId, learnerNeed) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.preLesson.learnerNeeds = learnerNeed;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error adding learner need to pre-lesson.", error);
  }
}

export async function preLessonSetIdentifyingStandard(
  userId,
  lessonId,
  identifiedStandard
) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.preLesson.identifiedStandard = identifiedStandard;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error adding identified standard to pre-lesson.", error);
  }
}

export async function preLessonSetFinished(userId, lessonId) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.preLessonSet = true;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error adding identified standard to pre-lesson.", error);
  }
}

// LEARNING OBJECTIVE FUNCTIONS

export async function learningObjectiveSlide2(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    callback(data.preLesson.classDescription, data.preLesson.academicStandard);
  } catch (error) {
    console.error(
      "Error retrieving academic standard and class description for step 2..n of learning objective.",
      error
    );
  }
}

export async function learningObjectiveSlide3(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    callback(
      data.preLesson.classDescription,
      data.preLesson.academicStandard,
      data.learningObjective.audience
    );
  } catch (error) {
    console.error(
      "Error retrieving pre-lesson and LO info for generating behavior",
      error
    );
  }
}

export async function learningObjectiveSlide4(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    callback(
      data.preLesson.classDescription,
      data.preLesson.academicStandard,
      data.learningObjective.audience,
      data.learningObjective.behavior
    );
  } catch (error) {
    console.error(
      "Error retrieving pre-lesson and LO info for generating condition",
      error
    );
  }
}

export async function learningObjectiveSlide5(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    callback(
      data.preLesson.classDescription,
      data.preLesson.academicStandard,
      data.learningObjective.audience,
      data.learningObjective.behavior,
      data.learningObjective.condition
    );
  } catch (error) {
    console.error(
      "Error retrieving pre-lesson and LO info for generating degree",
      error
    );
  }
}

export async function learningObjectiveSlide6(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    callback(
      data.preLesson.classDescription,
      data.preLesson.academicStandard,
      data.learningObjective.audience,
      data.learningObjective.behavior,
      data.learningObjective.condition,
      data.learningObjective.degree
    );
  } catch (error) {
    console.error(
      "Error retrieving pre-lesson and LO info for generating final learning objective",
      error
    );
  }
}

export async function learningObjectiveSlide7(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    callback(
      data.learningObjective.audience,
      data.learningObjective.behavior,
      data.learningObjective.condition,
      data.learningObjective.degree,
      data.learningObjective.finalObjective
    );
  } catch (error) {
    console.error(
      "Error retrieving pre-lesson and LO info for LO summary page",
      error
    );
  }
}

export async function learningObjectiveSetAudience(userId, lessonId, audience) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.learningObjective.audience = audience;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error adding 'audience' to learning objective", error);
  }
}

export async function learningObjectiveSetBehavior(userId, lessonId, behavior) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.learningObjective.behavior = behavior;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error adding 'behavior' to learning objective", error);
  }
}

export async function learningObjectiveSetCondition(
  userId,
  lessonId,
  condition
) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);
  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.learningObjective.condition = condition;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error adding 'condition' to learning objective", error);
  }
}

export async function learningObjectiveSetDegree(userId, lessonId, degree) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);
  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.learningObjective.degree = degree;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error adding 'degree' to learning objective", error);
  }
}

export async function learningObjectiveSetFinalLearningObjective(
  userId,
  lessonId,
  finalLearningObjective
) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);
  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.learningObjective.finalObjective = finalLearningObjective;
    await set(lessonRef, data);
  } catch (error) {
    console.error(
      "Error adding 'final objective' to learning objective",
      error
    );
  }
}

export async function learningObjectiveSetFinished(userId, lessonId) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.learningObjectiveSet = true;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error adding final learning objective to LO.", error);
  }
}

export async function setLessonIsShared(userId, lessonId) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.isShared = true;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error making teacher's lesson plan set to shared..", error);
  }
}

// ASSESSMENT FUNCTIONS

export async function assessmentSlide1(userId, callback) {
  const lessonsRef = ref(database, "lessons/" + userId + "/");

  try {
    const snapshot = await get(lessonsRef);
    const data = snapshot.val();
    const lessonsToReturn = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const lesson = data[key];
        // Constructing the object with required properties
        const lessonObject = {
          learningObjective: lesson.learningObjective.finalObjective,
          date: lesson.date,
        };
        // Adding the object to the lessonsToReturn array
        lessonsToReturn.push(lessonObject);
      }
    }
    callback(lessonsToReturn);
  } catch (error) {
    console.log("Error retrieving learning objectives for user.", error);
  }
}

export async function assessmentSlide2(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    let learningObjective = data.learningObjective.finalObjective;
    let behavior = data.learningObjective.behavior;
    let degree = data.learningObjective.degree;

    console.log(
      `learning objective: ${learningObjective}\nbehavior: ${behavior}\ndegree: ${degree}`
    );

    callback(learningObjective, behavior, degree);
  } catch (error) {
    console.error(
      "Error retrieving learning objective, behavior, and degree to create assessment for.",
      error
    );
  }
}

export async function assessmentSetAssessmentPrimer(userId, lessonId, primer) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    data.assessment.primer = primer;

    await set(lessonRef, data);
  } catch (error) {
    console.error("Error setting assessment primer.", error);
  }
}

export async function assessmentSlide3(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    let learningObjective = data.learningObjective.finalObjective;
    let behavior = data.learningObjective.behavior;
    let degree = data.learningObjective.degree;

    callback(learningObjective, behavior, degree);
  } catch (error) {
    console.error(
      "Error retrieving learning objective, behavior, and degree to create assessment for.",
      error
    );
  }
}

export async function assessmentSlide4(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    let learningObjective = data.learningObjective.finalObjective;
    let finalAssessment = data.assessment.assessmentDescription;

    callback(learningObjective, finalAssessment);
  } catch (error) {
    console.error(
      "Error retrieving learning objective, behavior, and degree to create assessment for.",
      error
    );
  }
}

export async function assessmentSetNewLearningObjective(
  userId,
  lessonId,
  newLearningObjective
) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    data.learningObjective.finalObjective = newLearningObjective;

    await set(lessonRef, data);
  } catch (error) {
    console.error("Error setting new learning objective", error);
  }
}

export async function assessmentSetPreAssessmentFeedback(
  userId,
  lessonId,
  preAssessment
) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    data.assessment.preAssessmentFeedback = preAssessment;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error setting pre-assessment feedback.", error);
  }
}

export async function assessmentSetFinished(
  userId,
  lessonId,
  finalAssessmentDescription
) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.assessmentSet = true;
    data.assessment.assessmentDescription = finalAssessmentDescription;
    await set(lessonRef, data);
  } catch (error) {
    console.error(
      "Error making teacher's assessmet in lesson plan set to shared..",
      error
    );
  }
}

// LESSON FLOW

export async function lessonFlowSlide2(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    let academicStandard = data.preLesson.academicStandard;
    let classroomDescription = data.preLesson.classroomDescription;
    let learningObjective = data.learningObjective.finalObjective;

    callback(classroomDescription, academicStandard, learningObjective);
  } catch (error) {
    console.error("Error retrieving info for lesson flow slide 2.", error);
  }
}

export async function lessonFlowSlide3(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    let academicStandard = data.preLesson.academicStandard;
    let classroomDescription = data.preLesson.classroomDescription;
    let learningObjective = data.learningObjective.finalObjective;
    let anticipatorySet = data.lessonFlow.anticipatorySet;

    callback(
      classroomDescription,
      academicStandard,
      learningObjective,
      anticipatorySet
    );
  } catch (error) {
    console.error("Error retrieving info for lesson flow slide 3.", error);
  }
}

export async function lessonFlowSlide4(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    let academicStandard = data.preLesson.academicStandard;
    let classroomDescription = data.preLesson.classroomDescription;
    let learningObjective = data.learningObjective.finalObjective;
    let anticipatorySet = data.lessonFlow.anticipatorySet;
    let objectiveAndPurpose = data.lessonFlow.objectiveAndPurpose;

    callback(
      classroomDescription,
      academicStandard,
      learningObjective,
      anticipatorySet,
      objectiveAndPurpose
    );
  } catch (error) {
    console.error("Error retrieving info for lesson flow slide 4.", error);
  }
}

export async function lessonFlowSlide5(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    let academicStandard = data.preLesson.academicStandard;
    let classroomDescription = data.preLesson.classroomDescription;
    let learningObjective = data.learningObjective.finalObjective;
    let anticipatorySet = data.lessonFlow.anticipatorySet;
    let objectiveAndPurpose = data.lessonFlow.objectiveAndPurpose;
    let input = data.lessonFlow.input;

    callback(
      classroomDescription,
      academicStandard,
      learningObjective,
      anticipatorySet,
      objectiveAndPurpose,
      input
    );
  } catch (error) {
    console.error("Error retrieving info for lesson flow slide 5.", error);
  }
}

export async function lessonFlowSlide6(userId, lessonId, callback) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    let anticipatorySet = data.lessonFlow.anticipatorySet;
    let objectiveAndPurpose = data.lessonFlow.objectiveAndPurpose;
    let input = data.lessonFlow.input;
    let modeling = data.lessonFlow.modeling;

    callback(anticipatorySet, objectiveAndPurpose, input, modeling);
  } catch (error) {
    console.error("Error retrieving info for lesson flow slide 5.", error);
  }
}

export async function lessonFlowSlide7(userId, lessonId, callback) {}

export async function lessonFlowSetFinished(userId, lessonId) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.lessonFlowSet = true;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error setting lesson flow to set..", error);
  }
}

export async function lessonFlowSetMaterialConstraints(
  userId,
  lessonId,
  materialConstraints
) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    data.lessonFlow.materialConstraints = materialConstraints;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error setting material constraints.", error);
  }
}

export async function lessonFlowSetAnticipatorySet(
  userId,
  lessonId,
  anticipatorySet
) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    data.lessonFlow.anticipatorySet = anticipatorySet;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error setting anticipatory set.", error);
  }
}

export async function lessonFlowSetObjectiveAndPurpose(
  userId,
  lessonId,
  objectiveAndPurpose
) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    data.lessonFlow.objectiveAndPurpose = objectiveAndPurpose;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error setting objective and purpose.", error);
  }
}

export async function lessonFlowSetInput(userId, lessonId, input) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    data.lessonFlow.input = input;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error setting input.", error);
  }
}

export async function lessonFlowSetModeling(userId, lessonId, modeling) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    data.lessonFlow.modeling = modeling;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error setting modeling.", error);
  }
}

export async function lessonFlowSetConsiderations(
  userId,
  lessonId,
  considerations
) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();

    data.lessonFlow.materialConsiderations = considerations;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error setting modeling.", error);
  }
}

export async function setLessonAsComplete(userId, lessonId) {
  const lessonRef = ref(database, "lessons/" + userId + "/" + lessonId);

  try {
    const snapshot = await get(lessonRef);
    const data = snapshot.val();
    data.lessonComplete = true;
    await set(lessonRef, data);
  } catch (error) {
    console.error("Error setting lesson plan as finished", error);
  }
}
