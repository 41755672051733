import React, { useState, useEffect } from "react";
import ABreadcrumb from "./ABreadcrumb";
import { useNavigate } from "react-router-dom";
import OpenAI from "openai";
import { bouncy } from "ldrs";

import {
  assessmentSlide1,
  assessmentSlide2,
  assessmentSlide3,
  assessmentSlide4,
  assessmentSetNewLearningObjective,
  assessmentSetAssessmentPrimer,
  assessmentSetFinished,
} from "../../../Components/Authentication/Firebase";

export default function ACoordinator({ lessonId, uid }) {
  const [step, setStep] = useState(1);

  const navigate = useNavigate("");
  function navigateTo(destination) {
    navigate(destination);
  }

  function updateStep(direction) {
    if (direction === "forward") {
      if (step === 4) {
        navigateTo(`/l/${lessonId}`);
      } else {
        setStep(step + 1);
      }
    } else if (direction === "back") {
      if (step === 1) {
        navigateTo(`/l/${lessonId}`);
      } else {
        setStep(step - 1);
      }
    } else {
      return;
    }
  }

  let content;
  if (step === 1) {
    content = (
      <AStepOne
        stepChangeCallback={updateStep}
        lessonId={lessonId}
        userId={uid}
      />
    );
  } else if (step === 2) {
    content = (
      <AStepTwo
        stepChangeCallback={updateStep}
        lessonId={lessonId}
        userId={uid}
      />
    );
  } else if (step === 3) {
    content = (
      <AStepThree
        stepChangeCallback={updateStep}
        lessonId={lessonId}
        userId={uid}
      />
    );
  } else if (step === 4) {
    content = (
      <AStepFour
        stepChangeCallback={updateStep}
        lessonId={lessonId}
        userId={uid}
      />
    );
  } else {
    content = (
      <AStepOne
        stepChangeCallback={updateStep}
        lessonId={lessonId}
        userId={uid}
      />
    );
  }

  return (
    <div className="relative">
      {content}
      <div className="fixed bottom-0 left-0 w-full z-50 bg-white">
        <ABreadcrumb step={step} />
      </div>
    </div>
  );
}

export function AStepOne({ stepChangeCallback, userId, lessonId }) {
  const [chosenLearningObjective, setChosenLearningObjective] = useState("");
  const [direction, setDirection] = useState("");
  const [existingLearningObjectives, setExistingLearningObjectives] = useState(
    []
  );
  const [typedLearningObjective, setTypedLearningObjective] = useState("");

  function updateTypedLearningObjective(event) {
    setTypedLearningObjective(event.target.value);
    setChosenLearningObjective(event.target.value);
  }

  function moveFromStep(direction) {
    console.log(
      `LEARNING OBJECTIVE SELECTED: ${typedLearningObjective}\n${chosenLearningObjective}`
    );

    if (direction === "back") {
      stepChangeCallback(direction);
    } else if (direction === "forward") {
      if (chosenLearningObjective === "") {
        setDirection("Please choose a learning objective before proceeding.");
        return;
      } else {
        // ### FOR PRODUCTION, YOU WILL ONLY USE THE LEARNING OBJECTIVE CORRESPONDING TO THAT LESSON ###
        if (typedLearningObjective !== "") {
          assessmentSetNewLearningObjective(
            userId,
            lessonId,
            typedLearningObjective
          );
        } else {
          assessmentSetNewLearningObjective(
            userId,
            lessonId,
            chosenLearningObjective
          );
        }
        stepChangeCallback(direction);
      }
    } else {
      return;
    }
  }

  function onRenderCallback(lessons) {
    setExistingLearningObjectives(lessons);
  }

  useEffect(() => {
    assessmentSlide1(userId, onRenderCallback);
  }, []);

  const [selectedObjective, setSelectedObjective] = useState(null);
  const handleCardClick = (index, lo) => {
    setSelectedObjective(index);
    setChosenLearningObjective(lo);
  };

  const [enteringExistingLO, setEnteringExistingLO] = useState(true);

  return (
    <div className="mr-32">
      <h5 className="font-semibold text-2xl">Preparing an Assessment</h5>
      <p className="font-light">
        To properly prepare an assessment for your lesson plan, we will base our
        discussion and feedback on the learning objective you've created for
        your lesson. Below, either choose a previously created learning
        objective or provide a new one.
      </p>

      <div className="flex flex-row justify-start items-center mt-10">
        <div class="inline-flex rounded-md shadow-sm" role="group">
          <button
            autoFocus={true}
            onClick={() => setEnteringExistingLO(true)}
            type="button"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-s-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white"
          >
            <svg
              class="w-3 h-3 me-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
            </svg>
            Choose existing learning objective
          </button>
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-l-0 border-gray-900 rounded-e-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white"
            onClick={() => setEnteringExistingLO(false)}
          >
            <svg
              class="w-3 h-3 me-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
              <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
            </svg>
            Enter new learning objective
          </button>
        </div>
      </div>

      {enteringExistingLO ? (
        <div>
          {existingLearningObjectives.length === 0 ? (
            <div className="flex flex-col items-center justify-center mt-8">
              <div>
                <h1 className="font-light mb-2 text-md">
                  No existing learning objectives.
                </h1>
              </div>
              <div className="mt-2">
                <button className="bg-white p-2 border-black border-solid border rounded-md">
                  Create learning objective
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center mt-8">
              <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                {existingLearningObjectives.map((objective, index) => (
                  <div
                    key={index}
                    className={`rounded-md border-solid border p-4 flex flex-col items-center hover:cursor-pointer hover:shadow-md ${
                      selectedObjective === index
                        ? "border-purple-800 shadow-lg"
                        : "border-black"
                    }`}
                    onClick={() =>
                      handleCardClick(index, objective.learningObjective)
                    }
                  >
                    <span className="self-start text-sm">{objective.date}</span>
                    <p className="text-start mt-2">
                      {objective.learningObjective}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-start items-start mt-8 mb-10">
          <input
            onChange={updateTypedLearningObjective}
            className="p-2 text-sm rounded-md border border-black border-solid w-[500px]"
            placeholder="By the end of this unit, 8th grade California honors biology students will be able to accurately create diagrams of 5 simple molecules, demonstrating their understanding of molecular structure and composition."
          ></input>
        </div>
      )}

      <div className="mt-4">
        <p className="text-sm font-light">{direction}</p>
      </div>

      <div class="flex mt-6">
        <a
          onClick={() => moveFromStep("back")}
          class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
        >
          Previous
        </a>
        <a
          onClick={() => moveFromStep("forward")}
          class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
        >
          Next
        </a>
      </div>
    </div>
  );
}

export function AStepTwo({ stepChangeCallback, userId, lessonId }) {
  bouncy.register();
  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [learningObjective, setLearningObjective] = useState("");
  const [behavior, setBehavior] = useState("");
  const [degree, setDegree] = useState("");

  const [direction, setDirection] = useState("");
  const [primer, setPrimer] = useState("");

  function moveFromStep(direction) {
    if (isLoading) {
      setDirection(
        "Please wait for your pre-assessment advice to finish generating before proceeding to the next step."
      );
      return;
    }

    if (direction === "back") {
      stepChangeCallback(direction);
    } else if (direction === "forward") {
      assessmentSetAssessmentPrimer(userId, lessonId, primer);
      stepChangeCallback(direction);
    } else {
      return;
    }
  }

  async function generatePrimer() {
    let finalPrompt = `Bloom's Taxonomy is a hierarchical framework used in education to categorize the cognitive processes involved in learning. It helps educators map out the learning that will take place in a lesson or an entire course. There are six levels. The levels start with the most basic cognitive processes and move to the most complex. The more complex the the cognitive process the higher-level of thinking that is taking place by a student. The levels of cognitive processes that make up Bloom’s Taxonomy are Knowledge, Comprehension, Appplicaion, Analysis, Synthesis, and Evaluuation. There is an updated version of Bloom’s Taxonomy that is also a hierarchical framework that starts with the most basic cognitive processes and moves to the most complex. The categories in this hierarchical framework include the following cognitive processes:  Remembering, Understanding, Applying, Analyzing, Evaluating, and Creating. These cognitive processes levels are similar to the original to those in Bloom’s Taxonmy hierarchy. Bloom's Taxonomy, and the updated Bloom’s Taxonomy, can be used to design effective learning objectives. The different cognitive process levels in the hierarchical frameworks can be associated with Behaviors that can be used to create learning objectives. Effective learning objectives are then matched with appropriate assessments that help educators determine a student’s level of learning.
    Remember or Knowledge
    Remember refers to the cognitive process of a student being able to retrieve relevant information from long-term memory. In other words, it is a student being able to “exhibit memory of previously learned material by recalling facts, terms, basic concepts, and answers.”
    Knowledge refers to the cognitive process that “involves the recall of specifics and universals, the recall of methods and processes, or the recall of a pattern, structure, or setting”.
    This cognitive process is about “recalling information or knowledge that is the foundation” of the hierarchical framework of Bloom’s Taxaonomy, and this information is a precondition for all subsequent higher levels of Bloom’s Taxonomy.
    This is the lowest level cognitive process that a student can exhibit.
    This is one of the lowest levels of learning a student can engage in.
    Let’s look at examples of what students would be able to do (the Behavior) at with this cognitive process level.
    List the name of three different legumes
    List is the Behavior
    Name three types of protein
    Name is the Behavior
    Label capital cities on a map of the United States
    Label is the Behavior
    Recognize symptoms of exhaustion
    Regonize is the Behavior
    Recall the steps to shooting a free-thrown
    Recall
    Indentfy strategies for retaining information
    Indentify
    The following are examples of different Behaviors that fit with Remember or Knowledge
    Cite
    Define
    Describe
    Enumerate
    Identify
    Index
    Indicate
    Label
    List
    Match
    Name
    Outline
    Point
    Quote
    Read
    Recall
    Recite
    Recognize
    Record
    Repeat
    Reproduce
    Review
    Select
    State
    Tabulate
    Underline
    Understand or Comprehension
    Understand refers to the cognitive process of a student being able to construct meaning from instructional messages, including oral, written and graphic communication.
    Comprehension refers to the cognitive process that “refers to a type of understanding or apprehension such that the individual knows what is being communicated and can make use of the material or idea being communicated without necessarily relating it to other material or seeing its fullest implications”.
    This is the second lowest level of cognitive process that a student can exhibit.
    This cognitive process is often mistaken for the Remember or Knowledge cognitive process.
    Let’s look at examples of what students would be able to do (the Behavior) at with this cognitive process level.
     Summarize features of a new piece of software
    Summarize is the Behavior
    Classify cooking oils by their levels of saturated fats
    Classify is the Behavior
    Clarify directions of a board game
    Clairy is the Behavior
    Predict one’s reaction to a song
    Predict is the Behavior
    The following are examples of different Behaviors that fit with Understand and Comprehension.
    Choose
    Clarify
    Classify
    Describe
    Explain
    Extend
    Illustrate
    Indicate
    Infer
    Indicate
    Interpret
    Paraphrase
    Predict
    Reorganize
    Review
    Rewrite
    Summarize
    Translate
    Apply or Application
    Apply refers to the cognitive process of a student being able to carry out or use a procedure in a given situation.
    Application refers to the cognitive process that “use of abstractions in particular and concrete situations”.
    It essence this cognitive process is about using information or skills or both in new or different situations.
    Let’s look at examples of what students would be able to do (the Behavior) at with this cognitive process level.
    Apply
    Build
    Calculate
    Construrct
    Diagnose
    Draw
    Execute
    Experiment
    Implement
    Operate
    Sketch
    Solve
    Analysis or Analyze or Synthesis
    Analysis represents the “breakdown of a communication into its constituent elements or parts such that the relative hierarchy of ideas is made clear and/or the relations between ideas expressed are made explicit.”
    Analyze refers to the ability to “break material into foundational parts and determine how parts relate to one another and the overall structure or purpose.”
    This cognitive process is considered a higher-level cognitive thinking process.
    Let’s look at examples of what students would be able to do (the Behavior) at with this cognitive process level.
    Select the most complete list of activities.
    Select is the Behavior
    Differentiate high ad low culture.
    Differentiate is the Behavior
    Integrate compliance and regulations.
    Integrate is the Behavior
    Deconstruct one’s biases.
    Deconstruct is the Behavior
    The following are examples of different Behaviors that fit with Analysis or Analyze.
    Attribute
    Classify
    Combine
    Compare
    Contrasts
    Diagram
    Differentiate
    Distinguish
    Examine
    Find
    Question
    Organize
    Relate
    Evaluation or Evaluate
    Evaluation is the process of making judgements about “the value of material and the methods for given purposes.”
    Evaluate refers to making judgments based on criteria and standards.
    This cognitive process is about justifying a decision or stand one takes
    Let’s look at examples of what students would be able to do (the Behavior) at with this cognitive process level.
    Check for consistency among sources of information
    Check is the Behavior
    Determine relevance of results
    Determine is the Behavior
    Judge efficiency of sampling techniques
    Judge is the Behavior
    Reflect on ones’ progress or performance
    Reflect is the Behavior
    The following are examples of different Behaviors that fit with Evalaution or Evaluate.
    Appraise
    Argue
    Check
    Critque
    Defend
    Determine
    Judge
    Predict
    Rank
    Recommend
    Select
    Support
    Weigh
    Create
    Create refers to the cognitive process of a student being able to “compile information together in a different way by combining elements in a new pattern or proposing alternative solutions”.
    This is often considered the highest level of cognitive process that a student can engage in.
    This cognitive process is about producing new or original work.
    Let’s look at examples of what students would be able to do (the Behavior) at with this cognitive process level.
    Generate a log of daily activities
    Generate is the Behavior.
    Assemble a team of experts
    Assemble s the Behavior.
    Design efficient project workflow
    Design s the Behavior.
    Create a learning portfolio
    Create is the Behavior.
    The following are examples of different Behaviors that fit with Evalaution or Evaluate.
    Assemble
    Design
    Develop
    Generate
    Invent
    Manage
    Plan
    Produce
    Propose
    Setup
    When using a learning objective to drive the selection of an assessment strategy, theapproach we suggest to use is the four categories of assessment. The four categories of assessment are Selected Response, Constructed Response or Extended Response, Performance Assessment, and Observations or Conversations. There are different assessment strategies that fit within the four assessment categories. The Behavior of a learning objective is the primary element that helps a teacher determine which assessment category to use. Once the teacher determines the assessment category to use, the teacher will then select an assessment strategy that fits under this assessment category. The assessment strategy should directly align with the learning objectives; in other words, the assessment strategy should allow the teacher to determine if a student has been able to meet the learning objective–specifically, the Behavior of the learning objective.
    Four Categories of Assessments
    Selected Response
    Selected Response focuses on determining if students are able to provide a finite answer about content knowledge. The Selected Response category focuses on information that can be objectively assessed. In other words, there is either a correct or incorrect response.
    The following assessment strategies are part of the the Selected Response type of assessment
    Agree or Disagree
    Choral Response
    Dry erase board responses
    Fill-in-the-blank
    Fist to Five
    Thumbs Up or Thumbs Down
    Turn-to-Your Neighbor
    An objective-type quiz or test (e.g., multiple choice, true or false, matching questions)
    Examples of traps educators fall into ‘Selected Response’
    Constructed Response or Exteneded Response
    Constructed Response or Exteneded Response focus on students providing responses that they have to construct about content knowledge. Students could be asked to provide an explanation to a complex problem. Students are being assessed on their conceptual understanding of ideas and content.
    The following assessment strategies are part of the the Constructed Response or Extended Response type of assessment
    Concept Map
    Diagram
    Essay
    Flowchart
    Graph
    Illustration
    Matrix
    Short Answer
    Table
    Web
    Examples of traps educators fall into Constructed Response or Exteneded Response
    Performance Assessment
    Performance Assessment focuses on students being able to carry out a specific activity typically as they are being watched or observed by someone (e.g., the teacher) who observes their performance or the results of the activity or both. The results of the activity could be a product that the student created based on their performance. Often, the student will be assessed on the skills they demonstrate during their performance–as well as the product that the student created based on their performance.
    The following assessment strategies are part of the the Performance Assessment type of assessment
    Debate
    Dramatization
    Enactment
    Exhibition
    Lab
    Model
    Movement
    Performance Task
    Presentation
    Recital
    Sport Skill
    Examples of traps educators fall into Performance Assessment
    Personal Conversations and Observations
    Personal Conversations and Observations focuses on student responding to various types of personal communication. Often, the communication takes place synchronously  with the teacher. The teacher will often ask a question or questions of the learner,  listen to the learner’s response, and then the teacher will typically immediately evaluate the response. The teacher will make a subjective evaluation according to the student response given. There are also asynchronous assessment strategies that teachers can use.
    The following assessment strategies are part of the the Personal Conversations and Observations type of assessment
    Checklist
    Conference
    Interviews
    Journal Sharing
    Observations
    Oral Questioning
    Peer Review
    Rating Scale
    Student Self-Assessment
    Thinking Aloud
    Examples of traps educators fall into Personal Conversations and Observations`;
    finalPrompt +=
      "\n\nYou are an instructional coach for K-12 educators. Above are your guidelines on the intersection between learning objectives and assessments when preparing assessment for students. Below, an educator comes to you with their learning objective, behavior, and degree (each from the ABCD framework for creating learning objectives). Based on this information, provide a paragraph of advice on things to keep in mind as they prepare their assessment. Do not write out the assessment for the educator, but give them high level guidelines based on the above principles. Be kind, helpful, and patient with your response.";
    let userPrompt = `Learning Objective: ${learningObjective}\nBehavior: ${behavior}\nDegree: ${degree}`;
    const completion = await openai.chat.completions.create({
      messages: [
        { role: "system", content: finalPrompt },
        { role: "user", content: userPrompt },
      ],
      model: "gpt-3.5-turbo-1106",
      stream: false, // implement at some point
      temperature: 0.2,
    });
    console.log(completion.choices[0].message.content);
    setPrimer(completion.choices[0].message.content);
    setIsLoading(false);
  }

  async function onRenderCallback(learningObjective, behavior, degree) {
    // Use to generate guidelines on what to think about for creating an assessment
    setLearningObjective(learningObjective);
    setBehavior(behavior);
    setDegree(degree);
    generatePrimer();
  }

  useEffect(() => {
    assessmentSlide2(userId, lessonId, onRenderCallback);
  }, []);

  return (
    <div>
      <div className="mr-20">
        <h5 className="font-semibold text-2xl">
          Assessment Intro and Learning Objective Review
        </h5>
        <p className="font-light leading-5 mt-1">
          As a reminder, assessment is a method to determine whether your
          students have met the learning objective. You gather data about your
          students using an assessment strategy. You can then evaluate the data
          to determine what your next steps should be in the instructional
          process.
        </p>
        <p className="font-light leading-5 mt-4">
          There are different types of assessments strategies that you can use
          in your lessons. The assessment strategy you choose should be based on
          what you have included in your learning objective. Often, a learning
          objective will have an indication of what the assessment strategy is.
        </p>
        <p className="font-light leading-5 mt-4">
          Let's look at your learning objective.
        </p>

        <h1 className="mt-8 font-semibold text-xl">Your learning objective</h1>
        <p>{learningObjective}</p>

        <h1 className="mt-8 font-semibold text-xl">What to keep in mind</h1>
        {isLoading ? (
          <div className="ml-3 mt-8">
            <l-bouncy size="45" speed="1.75" color="black"></l-bouncy>
          </div>
        ) : (
          <div className="">
            <p>{primer}</p>
          </div>
        )}
        <div className="mt-8">{direction}</div>

        {/* Navigation menu */}
        <div class="flex mt-12">
          <a
            onClick={() => moveFromStep("back")}
            class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
          >
            Previous
          </a>
          <a
            onClick={() => moveFromStep("forward")}
            class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
          >
            Next
          </a>
        </div>
      </div>
    </div>
  );
}

export function AStepThree({ stepChangeCallback, userId, lessonId }) {
  bouncy.register();
  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [currentResponse, setCurrentResponse] = useState("");
  const [userFeedback, setUserFeedback] = useState("");
  const [context, setContext] = useState([]);
  const [instruction, setInstruction] = useState("");

  const [learningObjective, setLearningObjective] = useState("");
  const [behavior, setBehavior] = useState("");
  const [degree, setDegree] = useState("");

  function onUserFeedbackUpdate(event) {
    setUserFeedback(event.target.value);
  }

  async function submitUserResponse() {
    setInstruction("");
    setIsLoading(true);

    if (context.length === 0) {
      let finalPrompt = `Bloom's Taxonomy is a hierarchical framework used in education to categorize the cognitive processes involved in learning. It helps educators map out the learning that will take place in a lesson or an entire course. There are six levels. The levels start with the most basic cognitive processes and move to the most complex. The more complex the the cognitive process the higher-level of thinking that is taking place by a student. The levels of cognitive processes that make up Bloom’s Taxonomy are Knowledge, Comprehension, Appplicaion, Analysis, Synthesis, and Evaluuation. There is an updated version of Bloom’s Taxonomy that is also a hierarchical framework that starts with the most basic cognitive processes and moves to the most complex. The categories in this hierarchical framework include the following cognitive processes:  Remembering, Understanding, Applying, Analyzing, Evaluating, and Creating. These cognitive processes levels are similar to the original to those in Bloom’s Taxonmy hierarchy. Bloom's Taxonomy, and the updated Bloom’s Taxonomy, can be used to design effective learning objectives. The different cognitive process levels in the hierarchical frameworks can be associated with Behaviors that can be used to create learning objectives. Effective learning objectives are then matched with appropriate assessments that help educators determine a student’s level of learning.
    Remember or Knowledge
    Remember refers to the cognitive process of a student being able to retrieve relevant information from long-term memory. In other words, it is a student being able to “exhibit memory of previously learned material by recalling facts, terms, basic concepts, and answers.”
    Knowledge refers to the cognitive process that “involves the recall of specifics and universals, the recall of methods and processes, or the recall of a pattern, structure, or setting”.
    This cognitive process is about “recalling information or knowledge that is the foundation” of the hierarchical framework of Bloom’s Taxaonomy, and this information is a precondition for all subsequent higher levels of Bloom’s Taxonomy.
    This is the lowest level cognitive process that a student can exhibit.
    This is one of the lowest levels of learning a student can engage in.
    Let’s look at examples of what students would be able to do (the Behavior) at with this cognitive process level.
    List the name of three different legumes
    List is the Behavior
    Name three types of protein
    Name is the Behavior
    Label capital cities on a map of the United States
    Label is the Behavior
    Recognize symptoms of exhaustion
    Regonize is the Behavior
    Recall the steps to shooting a free-thrown
    Recall
    Indentfy strategies for retaining information
    Indentify
    The following are examples of different Behaviors that fit with Remember or Knowledge
    Cite
    Define
    Describe
    Enumerate
    Identify
    Index
    Indicate
    Label
    List
    Match
    Name
    Outline
    Point
    Quote
    Read
    Recall
    Recite
    Recognize
    Record
    Repeat
    Reproduce
    Review
    Select
    State
    Tabulate
    Underline
    Understand or Comprehension
    Understand refers to the cognitive process of a student being able to construct meaning from instructional messages, including oral, written and graphic communication.
    Comprehension refers to the cognitive process that “refers to a type of understanding or apprehension such that the individual knows what is being communicated and can make use of the material or idea being communicated without necessarily relating it to other material or seeing its fullest implications”.
    This is the second lowest level of cognitive process that a student can exhibit.
    This cognitive process is often mistaken for the Remember or Knowledge cognitive process.
    Let’s look at examples of what students would be able to do (the Behavior) at with this cognitive process level.
     Summarize features of a new piece of software
    Summarize is the Behavior
    Classify cooking oils by their levels of saturated fats
    Classify is the Behavior
    Clarify directions of a board game
    Clairy is the Behavior
    Predict one’s reaction to a song
    Predict is the Behavior
    The following are examples of different Behaviors that fit with Understand and Comprehension.
    Choose
    Clarify
    Classify
    Describe
    Explain
    Extend
    Illustrate
    Indicate
    Infer
    Indicate
    Interpret
    Paraphrase
    Predict
    Reorganize
    Review
    Rewrite
    Summarize
    Translate
    Apply or Application
    Apply refers to the cognitive process of a student being able to carry out or use a procedure in a given situation.
    Application refers to the cognitive process that “use of abstractions in particular and concrete situations”.
    It essence this cognitive process is about using information or skills or both in new or different situations.
    Let’s look at examples of what students would be able to do (the Behavior) at with this cognitive process level.
    Apply
    Build
    Calculate
    Construrct
    Diagnose
    Draw
    Execute
    Experiment
    Implement
    Operate
    Sketch
    Solve
    Analysis or Analyze or Synthesis
    Analysis represents the “breakdown of a communication into its constituent elements or parts such that the relative hierarchy of ideas is made clear and/or the relations between ideas expressed are made explicit.”
    Analyze refers to the ability to “break material into foundational parts and determine how parts relate to one another and the overall structure or purpose.”
    This cognitive process is considered a higher-level cognitive thinking process.
    Let’s look at examples of what students would be able to do (the Behavior) at with this cognitive process level.
    Select the most complete list of activities.
    Select is the Behavior
    Differentiate high ad low culture.
    Differentiate is the Behavior
    Integrate compliance and regulations.
    Integrate is the Behavior
    Deconstruct one’s biases.
    Deconstruct is the Behavior
    The following are examples of different Behaviors that fit with Analysis or Analyze.
    Attribute
    Classify
    Combine
    Compare
    Contrasts
    Diagram
    Differentiate
    Distinguish
    Examine
    Find
    Question
    Organize
    Relate
    Evaluation or Evaluate
    Evaluation is the process of making judgements about “the value of material and the methods for given purposes.”
    Evaluate refers to making judgments based on criteria and standards.
    This cognitive process is about justifying a decision or stand one takes
    Let’s look at examples of what students would be able to do (the Behavior) at with this cognitive process level.
    Check for consistency among sources of information
    Check is the Behavior
    Determine relevance of results
    Determine is the Behavior
    Judge efficiency of sampling techniques
    Judge is the Behavior
    Reflect on ones’ progress or performance
    Reflect is the Behavior
    The following are examples of different Behaviors that fit with Evalaution or Evaluate.
    Appraise
    Argue
    Check
    Critque
    Defend
    Determine
    Judge
    Predict
    Rank
    Recommend
    Select
    Support
    Weigh
    Create
    Create refers to the cognitive process of a student being able to “compile information together in a different way by combining elements in a new pattern or proposing alternative solutions”.
    This is often considered the highest level of cognitive process that a student can engage in.
    This cognitive process is about producing new or original work.
    Let’s look at examples of what students would be able to do (the Behavior) at with this cognitive process level.
    Generate a log of daily activities
    Generate is the Behavior.
    Assemble a team of experts
    Assemble s the Behavior.
    Design efficient project workflow
    Design s the Behavior.
    Create a learning portfolio
    Create is the Behavior.
    The following are examples of different Behaviors that fit with Evalaution or Evaluate.
    Assemble
    Design
    Develop
    Generate
    Invent
    Manage
    Plan
    Produce
    Propose
    Setup
    When using a learning objective to drive the selection of an assessment strategy, theapproach we suggest to use is the four categories of assessment. The four categories of assessment are Selected Response, Constructed Response or Extended Response, Performance Assessment, and Observations or Conversations. There are different assessment strategies that fit within the four assessment categories. The Behavior of a learning objective is the primary element that helps a teacher determine which assessment category to use. Once the teacher determines the assessment category to use, the teacher will then select an assessment strategy that fits under this assessment category. The assessment strategy should directly align with the learning objectives; in other words, the assessment strategy should allow the teacher to determine if a student has been able to meet the learning objective–specifically, the Behavior of the learning objective.
    Four Categories of Assessments
    Selected Response
    Selected Response focuses on determining if students are able to provide a finite answer about content knowledge. The Selected Response category focuses on information that can be objectively assessed. In other words, there is either a correct or incorrect response.
    The following assessment strategies are part of the the Selected Response type of assessment
    Agree or Disagree
    Choral Response
    Dry erase board responses
    Fill-in-the-blank
    Fist to Five
    Thumbs Up or Thumbs Down
    Turn-to-Your Neighbor
    An objective-type quiz or test (e.g., multiple choice, true or false, matching questions)
    Examples of traps educators fall into ‘Selected Response’
    Constructed Response or Exteneded Response
    Constructed Response or Exteneded Response focus on students providing responses that they have to construct about content knowledge. Students could be asked to provide an explanation to a complex problem. Students are being assessed on their conceptual understanding of ideas and content.
    The following assessment strategies are part of the the Constructed Response or Extended Response type of assessment
    Concept Map
    Diagram
    Essay
    Flowchart
    Graph
    Illustration
    Matrix
    Short Answer
    Table
    Web
    Examples of traps educators fall into Constructed Response or Exteneded Response
    Performance Assessment
    Performance Assessment focuses on students being able to carry out a specific activity typically as they are being watched or observed by someone (e.g., the teacher) who observes their performance or the results of the activity or both. The results of the activity could be a product that the student created based on their performance. Often, the student will be assessed on the skills they demonstrate during their performance–as well as the product that the student created based on their performance.
    The following assessment strategies are part of the the Performance Assessment type of assessment
    Debate
    Dramatization
    Enactment
    Exhibition
    Lab
    Model
    Movement
    Performance Task
    Presentation
    Recital
    Sport Skill
    Examples of traps educators fall into Performance Assessment
    Personal Conversations and Observations
    Personal Conversations and Observations focuses on student responding to various types of personal communication. Often, the communication takes place synchronously  with the teacher. The teacher will often ask a question or questions of the learner,  listen to the learner’s response, and then the teacher will typically immediately evaluate the response. The teacher will make a subjective evaluation according to the student response given. There are also asynchronous assessment strategies that teachers can use.
    The following assessment strategies are part of the the Personal Conversations and Observations type of assessment
    Checklist
    Conference
    Interviews
    Journal Sharing
    Observations
    Oral Questioning
    Peer Review
    Rating Scale
    Student Self-Assessment
    Thinking Aloud
    Examples of traps educators fall into Personal Conversations and Observations`;
      finalPrompt +=
        "\n\nYou are an instructional coach for K-12 educators. Above are your guidelines on the intersection between learning objectives and assessments when preparing assessment for students. Below, an educator comes to you with their learning objective, behavior, and degree (each from the ABCD framework for creating learning objectives). Based on this information, provide a paragraph of advice on things to keep in mind as they prepare their assessment. Do not write out the assessment for the educator, but give them high level guidelines based on the above principles. Be kind, helpful, and patient with your response.";
      let userPrompt = `Learning Objective: ${learningObjective}\nBehavior: ${behavior}\nDegree: ${degree}\n\nThis is my assessment plan: ${userFeedback}`;
      const completion = await openai.chat.completions.create({
        messages: [
          { role: "system", content: finalPrompt },
          { role: "user", content: userPrompt },
        ],
        model: "gpt-3.5-turbo-1106",
        stream: false, // implement at some point
        temperature: 0.2,
      });

      // Update based on new completion
      console.log(completion.choices[0].message.content);
      setCurrentResponse(completion.choices[0].message.content);
      setContext([
        ...context,
        { role: "system", content: finalPrompt },
        { role: "user", content: userPrompt },
        { role: "assistant", content: completion.choices[0].message.content },
      ]);

      setIsLoading(false);
      assessmentSetFinished(userId, lessonId, userFeedback);
    } else {
      let newMessage = { role: "user", content: userFeedback };
      const completion = await openai.chat.completions.create({
        messages: [...context, newMessage],
        model: "gpt-3.5-turbo-1106",
        stream: false, // implement at some point
        temperature: 0.2,
      });

      // Update based on new completion
      console.log(completion.choices[0].message.content);
      setCurrentResponse(completion.choices[0].message.content);
      setContext([
        ...context,
        { role: "user", content: newMessage },
        { role: "assistant", content: completion.choices[0].message.content },
      ]);

      assessmentSetFinished(userId, lessonId, userFeedback);
      setIsLoading(false);
    }
  }

  async function onRenderCallback(learningObjective, behavior, degree) {
    setLearningObjective(learningObjective);
    setBehavior(behavior);
    setDegree(degree);
  }

  useEffect(() => {
    assessmentSlide3(userId, lessonId, onRenderCallback);
  }, []);

  function moveFromStep(direction) {
    if (direction === "back") {
      stepChangeCallback(direction);
    } else if (direction === "forward") {
      if (isLoading) {
        setInstruction(
          "Please wait for assessment feedback to finish loading before proceeding."
        );
        return;
      }

      if (currentResponse === "") {
        setInstruction(
          "Please submit an assessment outline before proceeding."
        );
        return;
      }
      console.log(userFeedback);
      stepChangeCallback(direction);
    } else {
      return;
    }
  }

  return (
    <div className="mr-20">
      <h5 className="font-semibold text-2xl">
        Writing Your Assessment Outline
      </h5>
      <p className="font-light leading-5 mt-1">
        Describe your assessment plan in 3 to 4 sentences. Submit for feedback.
      </p>
      {isLoading === true ? (
        <div>
          {/* System response canvas */}
          <div className="w-full h-full bg-gray-50 rounded-md my-6 border border-gray-300">
            <div class="flex items-center justify-center w-full h-full">
              <l-bouncy size="45" speed="1.75" color="black"></l-bouncy>
            </div>
          </div>

          {/* User responses */}
          <div class="mb-6">
            <input
              type="text"
              id="default-input"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
        </div>
      ) : (
        <div>
          {/* System response canvas */}
          <div className="w-full h-full bg-gray-50 rounded-md my-6 border border-gray-300">
            <p className="py-4 px-6">{currentResponse}</p>
          </div>

          {/* User response */}
          <div class="mb-2 flex flex-row justify-between">
            <textarea
              value={userFeedback}
              onInputCapture={onUserFeedbackUpdate}
              type="text"
              id="default-input"
              class="bg-gray-50 min-h-20 max-h-28 h-28 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
            <button
              onClick={submitUserResponse}
              type="button"
              class="h-12 ml-2 mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
            >
              Submit
            </button>
          </div>
          <p className="font-light text-sm mb-6">{instruction}</p>
        </div>
      )}

      {/* Navigation menu */}
      <div class="flex mt-12">
        <a
          onClick={() => moveFromStep("back")}
          class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
        >
          Previous
        </a>
        <a
          onClick={() => moveFromStep("forward")}
          class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
        >
          Next
        </a>
      </div>
    </div>
  );
}

export function AStepFour({ stepChangeCallback, userId, lessonId }) {
  useEffect(() => {
    assessmentSlide4(userId, lessonId, onRenderCallback);
  }, []);

  const [learningObjective, setLearningObjective] = useState("");
  const [assessment, setAssessment] = useState("");
  function onRenderCallback(learningObjective, finalAssessment) {
    setLearningObjective(learningObjective);
    setAssessment(finalAssessment);
  }

  function moveFromStep(direction) {
    if (direction === "back") {
      stepChangeCallback(direction);
    } else if (direction === "forward") {
      stepChangeCallback(direction);
    } else {
      return;
    }
  }

  return (
    <div className="mr-32">
      <h5 className="font-semibold text-2xl">Final Assessment</h5>
      <p className="font-light">
        Nice job! Below is the final assessment outline you wrote.
      </p>

      <h1 className="mt-8 font-semibold text-lg">Learning Objective</h1>
      <h1 className="font-light text-md">{learningObjective}</h1>

      <h1 className="mt-4 font-semibold text-lg">Assessment Outline</h1>
      <h1 className="font-light text-md">{assessment}</h1>

      <h1 className="mt-8 font-semibold text-lg">
        Additional things to consider
      </h1>
      <h1 className="font-light text-md">{`
      Before you move on to create your lesson, consider the following:
`}</h1>
      <ol className="list-inside list-decimal ml-4 font-light text-md">
        <li className="mt-1">
          Have you provided students with different strategies to demonstrate
          what they have learned?
        </li>
        <li className="mt-2">
          Will you need to create any materials or other supporting elements for
          the assessment strategy?{" "}
        </li>
        <li className="mt-2">
          How long do you anticipate the assessment strategy will take to
          implement? In other words, how long will you give your students to
          complete it?
        </li>
        <li className="mt-2">When will it be give to students?</li>
      </ol>

      <div class="flex mt-6">
        <a
          onClick={() => moveFromStep("back")}
          class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
        >
          Previous
        </a>
        <a
          onClick={() => moveFromStep("forward")}
          class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
        >
          Next
        </a>
        <button className="ml-2 text-sm rounded-md bg-black text-white p-2 border border-solid">
          {" "}
          Start Designing My Lesson
        </button>
      </div>
    </div>
  );
}
