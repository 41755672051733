import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { checkAuthStatus } from "../../Components/Authentication/AuthDetails";
import { setLessonIsShared, checkIfLessonExists, updateLessonTitle, updateLessonDescription, updateLessonTitleAndDescriptionSameTime } from "../../Components/Authentication/Firebase";
import { useNavigate } from "react-router-dom";

// Miscellaneous components
import NavBar from './NavBar';
import './LessonPage.css';
import { deleteLesson } from "../../Components/Authentication/Firebase";
import LessonBreadcrumb from "./LessonBreadcrumb";
import LoadingDescription from "./LoadingDescription";
import LoadingOptions from "./LoadingOptions";

function LessonPage(){

    function getBetaAccess(){
        const mailtoLink = `mailto:sam@classroomgpt.app`;
        window.location.href = mailtoLink;
    }
    
    // Authentication
    const { lessonId } = useParams();
    const [uid, setUid] = useState('');
    const [exists, setExists] = useState(false);

    // Leson information
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [description, setDescription] = useState('');

    const [visibilityChanged, setVisibilityChanged] = useState(false);
    const [visibilityChecked, setVisibilityChecked] = useState(false);
    function handleVisibilityChange(){
        setVisibilityChanged(true);
        setVisibilityChecked(!visibilityChecked);
    }

    // Pre-lesson
    const [learnersNeeds, setLearnerNeeds] = useState('');
    const [identifiedStandard, setIdentifiedStandard] = useState('');

    // Learning objective
    const [learningObjective, setLearningObjective] = useState('');

    // Assessment
    const [preAssessment, setPreAssessment] = useState('');
    const [formativeAssessment, setFormativeAssessment] = useState('');
    const [summativeAssessment, setSummativeAssessment] = useState('');

    // Lesson flow
    const [lessonFlow, setLessonFlow] = useState('');

    const [anticipatorySet, setAnticipatorySet] = useState('');
    const [objectiveAndPurpose, setObjectiveAndPurpose] = useState('');
    const [input, setInput] = useState('');
    const [modeling, setModeling] = useState('');


    const [preLessonSet, setPreLessonSet] = useState(false);
    const [learningObjectiveSet, setLearningObjectiveSet] = useState(false);
    const [assessmentSet, setAssessmentSet] = useState(false);
    const [lessonFlowSet, setLessonFlowSet] = useState(false);

    // Loading page
    const [descriptionIsLoading, setDescriptionIsLoading] = useState(true);
    const [optionsIsLoading, setOptionsIsLoading] = useState(true);

    const navigate = useNavigate('');
    function navigateTo(destination){
        navigate(destination);
    }

    const [newLessonTitle, setNewLessonTitle] = useState('');
    const [newLessonDescription, setNewLessonDescription] = useState('');

    function updateNewLessonTitle(event){setNewLessonTitle(event.target.value)};
    function updateNewLessonDescription(event){setNewLessonDescription(event.target.value)};

    function updateLessonTitleAndDescription(){

        if (newLessonTitle !== "" && newLessonDescription !== ""){
            updateLessonTitleAndDescriptionSameTime(uid, lessonId, newLessonTitle, newLessonDescription);
        } else if (newLessonTitle === "" && newLessonDescription !== ""){
            updateLessonDescription(uid, lessonId, newLessonDescription);
        } else if (newLessonTitle !== "" && newLessonDescription === ""){
            updateLessonTitle(uid, lessonId, newLessonTitle);
        } else {}

        setNewLessonTitle('')
        setNewLessonDescription('');
    }

    function updateLessonVisibility(){
        setLessonIsShared(uid, lessonId);
    }

     // Delay load by 250 miliseconds
    const delay = setTimeout(() => {
      setDescriptionIsLoading(false);
      setOptionsIsLoading(false);
    }, 400);

    function seeIfLessonExists(jsonData){
        if (jsonData === 'null'){
            setExists(false);
        } else {
            setExists(true)
            let parsedJsonData = JSON.parse(jsonData);


            // Lesson plan information
            if (parsedJsonData.description === null){
                setExists(false);
                return;
            } else {
                setDescription(parsedJsonData.description);
            }


            if (parsedJsonData.title === null){
                setExists(false);
                return;
            } else {
                setTitle(parsedJsonData.title)
                document.title = parsedJsonData.title;
            }

            if (parsedJsonData.date === null){
                setExists(false);
                return;
            } else {
                setDate(parsedJsonData.date);
            }
            

            // Lesson plan contents
            if (parsedJsonData.learningObjectiveSet){
                setLearningObjective(parsedJsonData.learningObjective.finalObjective);
                setLearningObjectiveSet(true);
            } else {
                setLearningObjective('Not yet created.');
                setLearningObjectiveSet(false);
            }

            if (parsedJsonData.assessmentSet){
                setPreAssessment(parsedJsonData.assessment.preAssessmentFeedback);
                setFormativeAssessment(parsedJsonData.assessment.formativeAssessmentFeedback);
                setSummativeAssessment(parsedJsonData.assessment.summativeAssessmentFeedback);
                setAssessmentSet(true);
            } else {
                setPreAssessment('Not created.');
                setFormativeAssessment('Not created.');
                setSummativeAssessment('Not created.');
                setAssessmentSet(false);
            }

            if (parsedJsonData.lessonFlowSet){
                setAnticipatorySet(parsedJsonData.lessonFlow.anticipatorySet);
                setObjectiveAndPurpose(parsedJsonData.lessonFlow.objectiveAndPurpose);
                setInput(parsedJsonData.lessonFlow.input);
                setModeling(parsedJsonData.lessonFlow.modeling);

                setLessonFlowSet(true);
            } else {
                setLessonFlow('Not yet created.');
                setLessonFlowSet(false);
            }

            if (parsedJsonData.preLessonSet){
                setIdentifiedStandard(parsedJsonData.preLesson.identifiedStandard);
                setLearnerNeeds(parsedJsonData.preLesson.learnerNeeds);
                setPreLessonSet(true);
            } else {
                setIdentifiedStandard('Not created.');
                setLearnerNeeds('Not created.')
                setPreLessonSet(false);
            }
        }
    }

    // Get uid, see if document id is in their directory
    useEffect(() => {
        const signedIn = checkAuthStatus();
        if (signedIn === null){
            navigateTo('/')
        } else {
            //console.log(`UID: ${signedIn.uid}\nLID: ${lessonId}\n`)
            checkIfLessonExists(signedIn.uid, lessonId, seeIfLessonExists);
            setUid(signedIn.uid)
            return () => clearTimeout(400);
        }
    }, [])

    // Delete lesson and navigate home
    function deleteTheLesson(){
        deleteLesson(uid, lessonId);
        navigateTo('/l/');
    }

    // 10-2 week 1 testing without the other lesson steps
    const [preLessonIsSelected, setPreLessonIsSelected] = useState(false);
    const [assessmentIsSelected, setAssessmentIsSelected] = useState(false);
    const [lessonFlowIsSelected, setLessonFlowIsSelected] = useState(false);

    function facetToEdit(facet){
        if (facet === "learningobjective"){
            navigateTo(`/e/${lessonId}?learningobjective`)
        } else if (facet === "assessment"){

            setAssessmentIsSelected(true);
            setPreLessonIsSelected(false);
            setLessonFlowIsSelected(false);
            navigateTo(`/e/${lessonId}?assessment`)

        } else if (facet === "lessonflow"){

            setLessonFlowIsSelected(true);
            setAssessmentIsSelected(false);
            setPreLessonIsSelected(false);
            //navigateTo(`/e/${lessonId}?lessonflow`)

        } else if (facet === "prelesson"){

            setPreLessonIsSelected(true);
            setLessonFlowIsSelected(false);
            setAssessmentIsSelected(false);
            //navigateTo(`/e/${lessonId}?prelesson`)
        
        } else {
            navigateTo(`/e/${lessonId}?learningobjective`)
        }
        
    }

    // See if on mobile - update visibility as necessary
    const [mobile, setMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Check the initial window size on mount

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    // NEW LOGIC: LOOK TO SEE IF BUILD/CONTENT/SETTINGS is visible
    const [build, setBuild] = useState(true);
    const [content, setContent] = useState(false);
    const [settings, setSettings] = useState(false);

    function changeMenu(changeTo){
        if (changeTo === "build"){
            setBuild(true);
            setContent(false);
            setSettings(false);
        } else if (changeTo === "content"){
            setBuild(false);
            setContent(true);
            setSettings(false);
        } else if (changeTo === "settings"){
            setBuild(false);
            setContent(false);
            setSettings(true);
        } else {
            setBuild(true);
            setContent(false);
            setSettings(false);
        }
    }

    if (exists){
        return (
            <div className="bg-[#F6F6F6] min-h-screen max-h-full">
                <NavBar />
                <div className="p-4 md:ml-64 pt-10 pl-10 pr-10">
                    <LessonBreadcrumb title={title} />

                    {/* Lesson plan information */}

                    {descriptionIsLoading ? (
                            <div className="mt-20">
                                <LoadingDescription />
                            </div>
                        ) : (
                            <div className="mt-10">
                                <h1 className="text-4xl font-semibold">{title}</h1>
                                <h1 className="mt-1 font-sans text-xl font-normal text-gray-700">{description}</h1>
                            </div>
                        )
                    }
                    {/* Options selection menu */}

                    {optionsIsLoading ? (
                        <div className="mt-10">
                            <LoadingOptions />
                        </div>
                    ) : (
                        <div>
                            <div class="border-b border-gray-200 mt-4">
                                <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 ">

                                    <li class="mr-2">
                                        <a 
                                            onClick={() => changeMenu('build')} 
                                            className={
                                                build
                                                ? "hover:cursor-pointer duration-200 ease-in-out inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active group"
                                                : "hover:cursor-pointer duration-200 ease-in-out inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group"
                                            }
                                        >
                                        <svg
                                            className={
                                                build
                                                ? "hover:cursor-pointer duration-200 ease-in-out w-4 h-4 mr-2 text-blue-600"
                                                : "hover:cursor-pointer duration-200 ease-in-out w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500"
                                            }
                                            aria-hidden="true" 
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 20 20">
                                            <path d="m13.835 7.578-.005.007-7.137 7.137 2.139 2.138 7.143-7.142-2.14-2.14Zm-10.696 3.59 2.139 2.14 7.138-7.137.007-.005-2.141-2.141-7.143 7.143Zm1.433 4.261L2 12.852.051 18.684a1 1 0 0 0 1.265 1.264L7.147 18l-2.575-2.571Zm14.249-14.25a4.03 4.03 0 0 0-5.693 0L11.7 2.611 17.389 8.3l1.432-1.432a4.029 4.029 0 0 0 0-5.689Z"/>
                                        </svg>Build
                                        </a>

                                    </li>
                                    <li class="mr-2">
                                        <a onClick={() => changeMenu('content')}
                                            className={
                                                content
                                                ? "hover:cursor-pointer duration-200 ease-in-out inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active group"
                                                : "hover:cursor-pointer duration-200 ease-in-out inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group"
                                            }
                                        >
                                            <svg 
                                            className={
                                                content
                                                ? "hover:cursor-pointer duration-200 ease-in-out w-4 h-4 mr-2 text-blue-600 mt-1"
                                                : "hover:cursor-pointer duration-200 ease-in-out w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 mt-1"
                                            } aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
                                            </svg>Content
                                        </a>
                                    </li>

                                    <li class="mr-2">
                                        <a onClick={() => changeMenu('settings')}
                                        className={
                                                settings
                                                ? "hover:cursor-pointer duration-200 ease-in-out inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active group"
                                                : "hover:cursor-pointer duration-200 ease-in-out inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group"
                                            }
                                        >
                                            <svg
                                            className={
                                                settings
                                                ? "hover:cursor-pointer duration-200 ease-in-out w-4 h-4 mr-2 text-blue-600"
                                                : "hover:cursor-pointer duration-200 ease-in-out w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500"
                                            } aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                                <path d="M18 7.5h-.423l-.452-1.09.3-.3a1.5 1.5 0 0 0 0-2.121L16.01 2.575a1.5 1.5 0 0 0-2.121 0l-.3.3-1.089-.452V2A1.5 1.5 0 0 0 11 .5H9A1.5 1.5 0 0 0 7.5 2v.423l-1.09.452-.3-.3a1.5 1.5 0 0 0-2.121 0L2.576 3.99a1.5 1.5 0 0 0 0 2.121l.3.3L2.423 7.5H2A1.5 1.5 0 0 0 .5 9v2A1.5 1.5 0 0 0 2 12.5h.423l.452 1.09-.3.3a1.5 1.5 0 0 0 0 2.121l1.415 1.413a1.5 1.5 0 0 0 2.121 0l.3-.3 1.09.452V18A1.5 1.5 0 0 0 9 19.5h2a1.5 1.5 0 0 0 1.5-1.5v-.423l1.09-.452.3.3a1.5 1.5 0 0 0 2.121 0l1.415-1.414a1.5 1.5 0 0 0 0-2.121l-.3-.3.452-1.09H18a1.5 1.5 0 0 0 1.5-1.5V9A1.5 1.5 0 0 0 18 7.5Zm-8 6a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z"/>
                                            </svg>Settings
                                        </a>
                                    </li>
                                </ul>
                            </div>
                    {/* Content to display */}
                    {build && 
                    <div className="mb-20">
                        <div className="mt-10 text-sm">
                            <div className="mt-4">
                                {/* Walkthrough steps menu */}


                                <div className='flex flex-row justify-left items-center mt-6'>
                                    <h1 className="font-sans text-2xl font-medium mr-2">Learning Objective</h1>
                                    {/* <span className={learningObjectiveSet ? "material-symbols-outlined text-green-800 mb-2" : "material-symbols-outlined text-yellow-500 mb-2"}>
                                        {learningObjectiveSet ? "check_circle" : "error"}
                                    </span> */}
                                </div>
                                <p className="mt-1 mb-2">Build a Learning Objective for your lesson with real-time feedback from an instructional designer.</p>
                                <button
                                    onClick={() => facetToEdit('learningobjective')}
                                    className={
                                        learningObjectiveSet
                                        ? "px-3 py-2 duration-200 ease-in-out rounded-md text-gray-900 border border-solid border-green-900 hover:bg-gray-100 hover:text-green-800"
                                        : "py-2.5 px-5 mr-2 mb-3 text-sm font-normal text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 "
                                    }>
                                    {learningObjectiveSet ? "Redo learning objective" : "Create learning objective"}
                                </button>

                                <div className='flex flex-row justify-left items-center mt-6'>
                                    <h1 className="font-sans text-2xl font-medium mb-2 mr-2">Assessment</h1>
                                    {/* <span className={assessmentSet ? "material-symbols-outlined text-green-800 mb-2" : "material-symbols-outlined text-yellow-500 mb-2"}>
                                        {assessmentSet ? "check_circle" : "error"}
                                    </span> */}
                                </div>
                                <p className="mt-1 mb-2">Create the assessment(s) for your lesson with real-time feedback from an instructional designer.</p>

                                <button
                                    onClick={() => facetToEdit('assessment')}
                                    className={
                                        assessmentSet
                                        ? "px-3 py-2 duration-200 ease-in-out rounded-md text-gray-900 border border-solid border-green-900 hover:bg-gray-100 hover:text-green-800"
                                        : "py-2.5 px-5 mr-2 mb-3 text-sm font-normal text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 "
                                    }>
                                    {assessmentSet ? "Redo assessment" : "Create assessment"}
                                </button>
                                {
                                    assessmentIsSelected ? (
                                        <div class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 overflow-hidden duration-200 ease-in-out transition-all max-h-screen" role="alert">
                                            <span class="font-medium">Info alert!</span> Assessment is currently in beta. To get access, click <a className="hover:cursor-pointer hover:underline hover:underline" onClick={getBetaAccess}><b>here</b></a>  or send an email to: sam@classroomgpt.app
                                        </div>
                                    ) : (
                                        <div></div>
                                    )
                                }


                                
                                
                                 <div className='flex flex-row justify-left items-center mt-6'>
                                    <h1 className="font-sans text-2xl font-medium mb-2 mr-2">Pre-Lesson</h1>
                                    {/* <span className={preLessonSet ? "material-symbols-outlined text-green-800 mb-2" : "material-symbols-outlined text-yellow-500 mb-2"}>
                                        {preLessonSet ? "check_circle" : "error"}
                                    </span> */}
                                </div>
                                <p className="mt-1 mb-2">Create a Pre-Lesson plan that analyzes your academic standard and learner needs.</p>
                                
                                <button
                                    onClick={() => facetToEdit('prelesson')} 
                                    className={
                                        preLessonSet
                                        ? "px-3 py-2 duration-200 ease-in-out rounded-md text-gray-900 border border-solid border-green-900 hover:bg-gray-100 hover:text-green-800"
                                        : "py-2.5 px-5 mr-2 mb-3 text-sm font-normal text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 "
                                    }>
                                    {preLessonSet ? "Redo pre-lesson" : "Create pre-lesson"}
                                </button>
                                {
                                    preLessonIsSelected ? (
                                        <div class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 overflow-hidden duration-200 ease-in-out transition-all max-h-screen" role="alert">
                                            <span class="font-medium">Info alert!</span> Pre Lesson is currently in beta. To get access, click <a className="hover:cursor-pointer hover:underline" onClick={getBetaAccess}><b>here</b></a>  or send an email to: sam@classroomgpt.app
                                        </div>
                                    ) : (
                                        <div></div>
                                    )
                                }
                                

                                
                                <div className='flex flex-row justify-left items-center mt-6'>
                                    <h1 className="font-sans text-2xl font-medium mb-2 mr-2">Lesson Flow</h1>
                                    {/* <span className={lessonFlowSet ? "material-symbols-outlined text-green-800 mb-2" : "material-symbols-outlined text-yellow-500 mb-2"}>
                                        {lessonFlowSet ? "check_circle" : "error"}
                                    </span> */}
                                </div>
                                <p className="mt-1 mb-2">Create a Lesson Flow plan that outlines the different content areas of your lesson.</p>
                                <button
                                    onClick={() => facetToEdit('lessonflow')}
                                    className={
                                        lessonFlowSet
                                        ? "px-3 py-2 duration-200 ease-in-out rounded-md text-gray-900 border border-solid border-green-900 hover:bg-gray-100 hover:text-green-800"
                                        : "py-2.5 px-5 mr-2 mb-3 text-sm font-normal text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 "
                                    }>
                                    {lessonFlowSet ? "Redo lesson flow" : "Create lesson flow"}
                                </button>
                                {
                                    lessonFlowIsSelected ? (
                                        <div class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 overflow-hidden duration-200 ease-in-out transition-all max-h-screen" role="alert">
                                            <span class="font-medium">Info alert!</span> Lesson Flow is currently in beta. To get access, click <a className="hover:cursor-pointer hover:underline transition duration-200 ease-in-out" onClick={getBetaAccess}><b>here</b></a>  or send an email to: sam@classroomgpt.app
                                        </div>
                                    ) : (
                                        <div></div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    }
                    {content && 
                    <div className="mt-10">
                        <div className="">
                            {/* Lesson plan information */}
                           
                            {/* 10-2 week 1 roll-out plan */}

                            {/* <h5 className="mt-6 text-2xl font-medium font-sans">Pre-Lesson</h5>
                            <h5 className="mt-2 font-normal">Learner Needs</h5>
                            <p className="font-light text-sm font-sans">{learnersNeeds}</p>
                            <h5 className="mt-2 font-normal">Identified Standard</h5>
                            <p className="font-light text-sm font-sans">{identifiedStandard}</p> */}

                            <h5 className="mt-6 text-2xl font-medium font-sans">Learning Objective</h5>
                            <h5 className="mt-2 font-normal">Final Objective</h5>
                            <p className="font-light text-sm font-sans">{learningObjective}</p>

                            {/* <h5 className="mt-6 text-2xl font-medium font-sans">Assessment</h5>
                            <h5 className="mt-2 font-normal">Pre-Assessment</h5>
                            <p className="font-light text-sm font-sans">{preAssessment}</p>
                            <h5 className="mt-2 font-normal">Formative Assessment</h5>
                            <p className="font-light text-sm font-sans">{formativeAssessment}</p>
                            <h5 className="mt-2 font-normal">Summative Assessment</h5>
                            <p className="font-light text-sm font-sans">{summativeAssessment}</p>

                            <h5 className="mt-6 text-2xl font-medium font-sans">Lesson Flow</h5>
                            <h5 className="mt-2 font-normal">Anticipatory Set</h5>
                            <p className="font-light text-sm font-sans">{anticipatorySet}</p>
                            <h5 className="mt-2 font-normal">Objective and Purpose</h5>
                            <p className="font-light text-sm font-sans">{objectiveAndPurpose}</p>
                            <h5 className="mt-2 font-normal">Input</h5>
                            <p className="font-light text-sm font-sans">{input}</p>
                            <h5 className="mt-2 font-normal">Modeling</h5>
                            <p className="font-light text-sm font-sans">{modeling}</p>
                             */}
                            {/* janky spacing */}
                            <div className="mb-10"></div>
                        </div>
                    </div>
                    }

                    {settings && 
                    <div className="mt-10">

                        <p className="font-sans text-xl font-semibold mb-2">Lesson details</p>

                        <div class="mb-6 mt-4 max-w-fit">
                            <label for="default-input" class="block mb-2 text-sm font-normal text-gray-90">Lesson title</label>
                            <input value={newLessonTitle} onInputCapture={updateNewLessonTitle} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <p id="helper-text-explanation" class="mt-2 text-sm text-gray-400 font-light">Teachers commonly use standard or textbook lesson numbers as titles.</p>
                        </div>

                        <div class="mb-6 mt-4 max-w-fit">
                            <label for="default-input" class="block mb-2 text-sm font-normal text-gray-90">Lesson description</label>
                            <input value={newLessonDescription} onInputCapture={updateNewLessonDescription} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <p id="helper-text-explanation" class="mt-2 text-sm text-gray-400 font-light">E.g. BIO1 honors introduction to structure of mitochondria</p>
                        </div>

                        {newLessonTitle !== '' || newLessonDescription !== '' ? (
                            <button
                                type="button"
                                onClick={updateLessonTitleAndDescription}
                                className="mt-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2"
                            >
                                <svg
                                className="mt-1 w-3.5 h-3.5 mr-2"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 18 21"
                                >
                                <path d="m19.822 7.431-4.846-7A1 1 0 0 0 14.153 0H1a1 1 0 0 0-.822 1.569L4.63 8 .178 14.431A1 1 0 0 0 1 16h13.153a1.001 1.001 0 0 0 .823-.431l4.846-7a1 1 0 0 0 0-1.138Z" />
                                </svg>
                                Update
                            </button>
                            ) : null}

                        <div>
                            <p className="font-sans text-xl font-semibold mt-16">Visibility</p>
                            <div className="mt-2">
                                <label class="relative inline-flex items-center mb-4 cursor-pointer">
                                    <input onChange={handleVisibilityChange} checked={visibilityChecked} type="checkbox" value="" class="sr-only peer" />
                                    <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                                    <span class="ml-3 text-sm font-medium text-gray-900">Share with other educators</span>
                                </label>
                            </div>
                            {
                                visibilityChanged ? (
                                    <div>
                                        <button
                                            type="button"
                                            onClick={updateLessonVisibility}
                                            className="mt-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2"
                                        >
                                            <svg
                                            className="mt-1 w-3.5 h-3.5 mr-2"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 18 21"
                                            >
                                            <path d="m19.822 7.431-4.846-7A1 1 0 0 0 14.153 0H1a1 1 0 0 0-.822 1.569L4.63 8 .178 14.431A1 1 0 0 0 1 16h13.153a1.001 1.001 0 0 0 .823-.431l4.846-7a1 1 0 0 0 0-1.138Z" />
                                            </svg>
                                            Update
                                        </button>
                                    </div>
                                ) : (
                                    <div></div>
                                )
                            }
                        </div>


                        <div className="mt-10 border-solid border-gray-700 rounded-lg max-w-fit mb-20">
                            <p className="font-sans text-xl font-semibold mb-2">Danger area</p>
                        <div className="flex flex-row justify-left">
                            <button onClick={() => deleteTheLesson()} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 ">
                                <svg class="w-3.5 h-3.5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 21">
                                    <path d="M17 4h-4V2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v2H1a1 1 0 0 0 0 2h1v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1a1 1 0 1 0 0-2ZM7 2h4v2H7V2Zm1 14a1 1 0 1 1-2 0V8a1 1 0 0 1 2 0v8Zm4 0a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0v8Z"/>
                                </svg>Delete this lesson
                            </button>
                        </div>
                        </div>

                    </div>
                    }
                        </div>
                    )}
                </div>
            </div>
        )
    } else {
        return(
            <div>

                {/* For some reason, this is flashing at random times, which creates a poor page rendering experience. Need to debug. */}

                <NavBar />

                {/* <div className="mt-20 mr-20 ml-40">
                    <h1 className="font-serif text-4xl font-semibold">This lesson does not exist.</h1>
                    <h1 className="mt-1 font-sans text-lg font-light">Go back or just sit here. I don't care either way.</h1>
                </div> */}
            </div>
        )
    }
}

export default LessonPage;